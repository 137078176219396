import React from "react";
import styled from "styled-components";
//
import Item from "./item";
//
export default ({ items, ...rest }) => (
  <Wrap>
    {items.map((item, i) => (
      <Item key={i} {...item} />
    ))}
  </Wrap>
);
//
const Wrap = styled.div``;
