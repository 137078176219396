import React from "react";
import styled from "styled-components";
//
import { RichText } from "components/prismic";
//
import Menu from "./menu";
import Items from "./items";
import Footer from "./footer";
//
export default ({ description, image, title, top_text, ...rest }) => (
  <Wrap>
    <Image {...image}>
      <img id="top" src={image.url} alt={image.alt} />
    </Image>
    <Text>
      <h1>{title}</h1>
      <RichText {...description} />
    </Text>
    <Menu {...rest} />
    <Items {...rest} />
    <Footer {...rest} />
    {/* <pre>
      <code>{JSON.stringify(rest, null, 2)}</code>
    </pre> */}
  </Wrap>
);
//
const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 auto;
  p {
    max-width: 600px;
  }
`;
const Image = styled.div`
  @media (min-width: 768px) {
    background-image: url(${(image) => image.url});
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    height: 90vh;

    img {
      display: none;
    }
  }
`;
const Text = styled.div`
  background: rgba(255, 255, 255, 0.85);
  padding: 0.5rem 0.5rem 1rem 0.5rem
  position: relative;
  width: 100vw;
  h1 {
    font-size: 1.6em;
    margin: 0;
  }

  @media (min-width: 768px) {
    bottom: 20vh;
    margin: 0;
    padding: 1rem 1rem 1rem 2rem;
    position: absolute;
    right: 0;
    width: auto;
    h1 {
      font-size: 2.5em;
    }
  }
`;
