import React from "react";
import styled from "styled-components";
//
import { RichText } from "components/prismic";
//
export default ({ i, item_image, item_title, item_description, ...rest }) => (
  <Wrap key={i} id={item_title}>
    <Image {...item_image} />
    <Text>
      <Title>{item_title}</Title>
      <Description>
        <RichText {...item_description} />
      </Description>
    </Text>
  </Wrap>
);
//
const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

const Image = styled.div`
  background-image: url(${(item_image) => item_image.url});
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  height: calc(100vh - 68px);
  position: -webkit-sticky;
  position: sticky;
  top: 68px;
`;
const Text = styled.div`
  justify-content: center;
  margin: -25vh 0 -0.5px 0;
  padding: 0.5rem 0.5rem 2rem 0.5rem;
  display: grid;
  grid-template-columns: 1fr;
  background: rgba(255, 255, 255, 0.85);
  width: 100vw;
  z-index: 2000;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 2fr;
    padding: 2rem 1rem 4rem 1rem;
  }
`;
const Title = styled.h2`
font-size: 1.5em;
padding: 0 2rem
margin: 0.5rem;
justify-self: center;

  &:after {
    background-color: currentColor;
    content: "";
    display: block;
    height: 2px;
    margin: 0.5rem 0
    width: 4rem;
  }

  @media (min-width: 768px) {
    font-size: 2em;
    justify-self: end;
  }
`;
const Description = styled.div`
  margin: 0 auto;
  padding: 0;

  h3 {
    margin: 0 0.2rem;
  }

  @media (min-width: 768px) {
    margin: initial;
    padding: 0.5rem 1rem;
  }
`;
