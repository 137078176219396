import React from "react";
import styled from "styled-components";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
//
export default ({ title, items, ...rest }) => (
  <Wrap>
    <Menu>
      {items.map((item, i) => (
        <MenuItem key={i}>
          <Link
            key={i}
            activeClass="active"
            to={item.item_title}
            spy={true}
            offset={-68}
          >
            {item.item_title}
          </Link>
        </MenuItem>
      ))}
    </Menu>
  </Wrap>
);
//

const Wrap = styled.div`
  background-color: #fff;
  border-bottom: 2px solid #e6e6e6;
  border-top: 2px solid #e6e6e6;
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  z-index: 2002;
  padding: 0.5rem;
`;
const Menu = styled.nav`
  align-content: center;
  display: flex;
  flex-wrap: wrap;
  height: 50px;
  justify-content: center;
  justify-self: start;
`;
const MenuItem = styled.div`
  margin: 0 0.1rem;

  a {
    cursor: pointer;
    font-size: 0.9em;
    padding: 0.2rem;
  }

  @media (min-width: 768px) {
    min-height: 3rem;
    min-height: calc(3rem + 2px);
    margin: 0 0.25rem;
    line-height: 3rem;
    line-height: calc(3rem + 2px);
    white-space: nowrap;

    a {
      font-size: 1.3em;
      padding: 0.5rem;
    }
  }
`;
