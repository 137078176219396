import React from "react";
import styled from "styled-components";
//
import { RichText } from "components/prismic";
//
import Facebook from "components/svg/fontawesome/FacebookSquare";
import Instagram from "components/svg/fontawesome/Instagram";
import Mail from "components/svg/fontawesome/EnvelopeO";
//
export default ({ footer, email, facebook, instagram, ...rest }) => (
  <Wrap>
    {footer && <RichText {...footer} />}
    <Links>
      <Link>
        <a href={email.url}>
          <Mail />
        </a>
      </Link>

      <Link>
        <a href={facebook.url} target="_blank">
          <Facebook />
        </a>
      </Link>

      <Link>
        <a href={instagram.url} target="_blank">
          <Instagram />
        </a>
      </Link>
    </Links>
  </Wrap>
);
//
const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  padding: 2rem 1rem;
  text-align: center;
  width: 100vw;

  img {
    max-width: 50px;
    padding-top: 0.5rem;
  }

  a:hover {
    color: #6a5036;
  }
`;

const Links = styled.div`
  display: flex;
  margin: 0 auto;
`;
const Link = styled.div`
  width: 30px;
  height: auto;
  margin: 5px;
  svg {
    fill: #5d89ba;
    width: 100%;
  
    :hover {
      fill: initial;
    }
`;
